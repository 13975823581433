import { commonCodesGetCommonCode } from "@/utils/commonCodes";

export function getCommonCodeName(code, value) {
  if (value.length === 0) {
    return null;
  }
  return commonCodesGetCommonCode(code)
    .filter((data) => {
      if (value.includes(data.comCode)) {
        return true;
      }
      return false;
    })
    .map((data) => data.comName);
}

export function getReceiveCodeName(value) {
  if (value.length === 0) {
    return null;
  }
  return value.map((data) => (data === "Y" ? "수신" : "미수신"));
}

export function getOutCodeName(value) {
  if (value.length === 0) {
    return null;
  }
  return value.map((data) => (data === "Y" ? "탈회회원" : "회원"));
}

export function getDataRangeValue(value) {
  if (!value.from || !value.to) {
    return null;
  }
  return value.from + "~" + value.to;
}

export const couponSearchNameObj = {
  memberInfo: {
    title: "회원정보",
    memberDiv: {
      title: "회원구분",
      code: "MEMBER_DIV",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    memberGrade: {
      title: "회원등급",
      code: "MEMBER_GRADE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    memberName: {
      title: "회원명",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    memberNo: {
      title: "회원번호",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    sexCode: {
      title: "성별",
      code: "SEX_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    birthDay: { title: "생년월일" },
    birthYy: {
      title: "생년",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    birthMm: {
      title: "월",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    birthDd: {
      title: "일",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    age: {
      title: "연령",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    ageCodes: {
      title: "연령대",
      code: "AGE_DISTRB",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    hp4: {
      title: "핸드폰 뒤 4자리",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    smsReceive: {
      title: "SMS 수신여부",
      code: "receive",
      value: null,
      set dataSet(v) {
        this.value = getReceiveCodeName(v);
      },
    },
    marktInfoAgreFlag: {
      title: "마케팅수신정보",
      code: "receive",
      value: null,
      set dataSet(v) {
        this.value = getReceiveCodeName(v);
      },
    },
    cprDiv: {
      title: "법인구분",
      code: "CPR_DIV",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    cprName: {
      title: "법인명",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    wrkplc: {
      title: "근무처",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    acqsDate: {
      title: "취득일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    expireDate: {
      title: "만기일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    logCnt: {
      title: "접속횟수",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    isOut: {
      title: "탈회여부",
      code: "receive",
      value: null,
      set dataSet(v) {
        this.value = getOutCodeName(v);
      },
    },
    membershipStatus: {
      title: "회원권 상태",
      code: "MEMBERSHIP_STATUS",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    entryOrder: {
      title: "가입차수",
      code: "ENTRY_ORDER",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    nearbyCode: {
      title: "지역민구분",
      code: "NEARBY_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    areaCode: {
      title: "거주지역",
      code: "AREA_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
  },
  resveInfo: {
    title: "예약정보",
    resveDate: {
      title: "예약일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    resveTime: { title: "예약시간" },
    resveStartTime: {
      title: "예약시간 - 시작",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    resveEndTime: {
      title: "예약시간 - 종료",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    bsnCode: {
      title: "영업구분",
      code: "BSN_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    dwCode: {
      title: "요일",
      code: "DW_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    resveCnt: {
      title: "예약횟수",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    resveChannel: {
      title: "예약채널",
      code: "RESVE_CHANNEL",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    resveKind: {
      title: "예약종류",
      code: "RESVE_KIND",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    resveExFlag: {
      title: "기예약제외",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v ? "체크" : null;
      },
    },
    resveExDate: {
      title: "기예약제외 일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    resvePenaltyFlag: {
      title: "위약제외",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v ? "체크" : null;
      },
    },
    resvePenaltyDate: {
      title: "위약제외 일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
  },
  visitInfo: {
    title: "내장정보",
    visitDate: {
      title: "내장일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    visitTime: { title: "내장시간" },
    visitStartTime: {
      title: "내장시간 - 시작",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    visitEndTime: {
      title: "내장시간 - 종료",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    bsnCode: {
      title: "영업구분",
      code: "BSN_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    dwCode: {
      title: "요일",
      code: "DW_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    visitCnt: {
      title: "내장횟수",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    paymtCode: {
      title: "요금구분",
      code: "PAYMT_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
  },
  salesInfo: {
    title: "매출정보",
    salesDate: {
      title: "매출일자",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = getDataRangeValue(v);
      },
    },
    bsnCode: {
      title: "영업구분",
      code: "BSN_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    storeCode: {
      title: "매장",
      code: "STORE_CODE",
      value: null,
      set dataSet(v) {
        this.value = getCommonCodeName(this.code, v);
      },
    },
    salesDiv: {
      title: "매출구분",
      code: "SALES_DIV",
      value: null,
      set dataSet(v) {
        this.value = v === "P" ? "개인매출" : v === "T" ? "팀매출" : v;
      },
    },
    amt: {
      title: "매출액",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
    unitPrice: {
      title: "객단가",
      code: null,
      value: null,
      set dataSet(v) {
        this.value = v;
      },
    },
  },
  exclude: {
    title: "제외",
    members: {
      title: "회원",
      code: null,
      value: null,
      set dataSet(v) {
        const vm = v.map(item => item.memberName || "");
        this.value = vm.length > 0 ? vm : null;
      },
    },
  },
};
